import { QuestionInstance } from "../../Questions";

import reactStringReplace from "react-string-replace";

export const replacePlaceholders = (question?: QuestionInstance) => {
  if (!question) return null;
  let playerI = 0;
  let sipI = 0;

  const textWithSips = reactStringReplace(question.text, "%s", (_) => (
    <span
      style={{
        fontSize: "1.1em",
        fontFamily: "Action Man",
        color: "var(--ion-color-tertiary)",
      }}
    >
      {question.players[playerI++]}
    </span>
  ));

  const textWithPlayers = reactStringReplace(textWithSips, "$", (_) => (
    <span
      style={{
        fontFamily: "Action Man ",
        fontWeight: "bold",
        color: "var(--ion-color-danger)",
        fontSize: "1.35em",
        position: "relative",
        top: "0.1em",
      }}
    >
      {question.sips[sipI++]?.toString()}
    </span>
  ));

  return textWithPlayers;
};
