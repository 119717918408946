import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  useIonToast,
} from "@ionic/react";
import { removeCircle, addCircleOutline } from "ionicons/icons";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { randomElement } from "../../helpers";
import { addPlayer, removePlayer } from "../../redux/settings";
import { RootState } from "../../redux/store";
import { NAME_PLACEHOLDERS } from "../../constants";

export const PlayersCard: FC = (props) => {
  const [newName, setNewName] = useState("");
  const dispatch = useDispatch();
  const [present] = useIonToast();
  const clickAdd = () => {
    if (!newName) {
      return present({
        message: "Bitte gib einen Namen ein",
        duration: 2000,
        color: "warning",
      });
    }
    dispatch(addPlayer(newName));
    setNewName("");
  };
  const players = useSelector((state: RootState) => state.settings.players);

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle>
          {players.length} Saufnasen hinzugefügt
        </IonCardSubtitle>
        <IonCardTitle>Spieler</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonList
          style={{
            maxHeight: "30vh",
            minHeight: "5vh",
            overflow: "scroll",
          }}
        >
          {!players.length && (
            <IonItem lines="none">
              <i>Keine Spieler vorhanden</i>
            </IonItem>
          )}
          {players.map((p, i) => (
            <IonItem key={p + i}>
              <IonLabel>{p}</IonLabel>
              <IonButton
                slot="end"
                fill="clear"
                onClick={() => dispatch(removePlayer(p))}
              >
                <IonIcon icon={removeCircle} slot="icon-only"></IonIcon>
              </IonButton>
            </IonItem>
          ))}
        </IonList>
        <hr />
        <IonItem key="add-new" lines="none">
          <IonInput
            placeholder={randomElement(NAME_PLACEHOLDERS)}
            value={newName}
            onIonInput={(ev) => setNewName(ev.detail.value?.toString() ?? "")}
            label="Spielername"
            autocomplete="given-name"
            autocapitalize="on"
            autocorrect="on"
          ></IonInput>
        </IonItem>
        <IonButton onClick={clickAdd} expand="block" className="ion-padding">
          <IonIcon slot="start" icon={addCircleOutline}></IonIcon>
          Spieler hinzufügen
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};
