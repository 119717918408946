import { ScreenOrientation } from "@ionic-native/screen-orientation";

import {
  createAnimation,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonModal,
  IonPage,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import "./GamePage.css";
import {
  arrowRedoCircleOutline,
  arrowUndoCircleOutline,
  close,
  informationOutline,
  thumbsDownOutline,
  thumbsUpOutline,
  chevronUpSharp,
  chevronUp,
} from "ionicons/icons";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { endGame, nextQuestion, previousQuestion } from "../../redux/settings";
import { HEADING_STYLE, QUESTION_TYPE_TITLE } from "../../constants";
import { useRateQuestion, useStartGame } from "../../helpers";
import { useHistory } from "react-router";
import { replacePlaceholders } from "./replacePlaceholder";

export const GamePage: React.FC = () => {
  useEffect(() => {
    try {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE);
    } catch (e) {
      console.log("Cant look orientation!");
    }
  }, []);

  const questionRef = useRef<HTMLHeadingElement>(null);

  const startGame = useStartGame();
  const router = useIonRouter();
  const dispatch = useDispatch();
  const history = useHistory();

  const question = useSelector(
    (state: RootState) => state.settings.questions[state.settings.questionIndex]
  );

  const isGameOver = useSelector(
    (state: RootState) =>
      state.settings.questions.length === state.settings.questionIndex
  );

  const [currQuestionIndex, endQuestionIndex] = useSelector(
    (state: RootState) => [
      state.settings.questionIndex,
      state.settings.questions.length,
    ]
  );

  const rateQuestion = useRateQuestion(question);
  const questionText = useMemo(() => replacePlaceholders(question), [question]);

  let block = false;
  const onNextQuestion = async (next = true) => {
    if (block || !questionRef?.current) return;
    block = true;

    const pixels = next ? 50 : -50;

    const animation = createAnimation()
      .addElement(questionRef.current)
      .duration(250)
      .fromTo("opacity", "1", "0")
      .fromTo("transform", "translateX(0)", `translateX(${-1 * pixels}px)`);

    const reverseAnimation = createAnimation()
      .addElement(questionRef.current)
      .duration(250)
      .fromTo("opacity", "0", "1")
      .fromTo("transform", `translateX(${pixels}px)`, "translateX(0)");

    await animation?.play();
    if (next) dispatch(nextQuestion());
    else dispatch(previousQuestion());

    await reverseAnimation?.play();
    block = false;
  };

  if (isGameOver) {
    return (
      <IonPage>
        <IonContent>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <h1 style={HEADING_STYLE}>Das wars!</h1>
            <div style={{ height: "20px" }}></div>
            <div
              style={{
                width: "500px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <IonButton onClick={() => router.goBack()} fill="outline">
                Zurück!
              </IonButton>
              <IonButton onClick={() => startGame()}>
                Noch eine Runde!
              </IonButton>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }

  if (!question) {
    return (
      <IonPage>
        <IonContent>
          <h1 style={HEADING_STYLE}>Ups... hier ist etwas schief gegangen</h1>
          <IonButton onClick={() => router.goBack()}>Zurück!</IonButton>
        </IonContent>
      </IonPage>
    );
  }
  return (
    <IonPage>
      <IonContent>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div
            style={{
              top: "4%",
              right: "2%",
              position: "absolute",
              width: "96%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IonButtons>
              <IonButton
                fill="clear"
                onClick={() => {
                  dispatch(endGame());
                  history.replace("/");
                }}
              >
                <IonIcon slot="icon-only" icon={close}></IonIcon>
              </IonButton>
            </IonButtons>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              flexDirection: "column",
            }}
            ref={questionRef}
            onClick={() => onNextQuestion()}
          >
            <h1 style={HEADING_STYLE}>{QUESTION_TYPE_TITLE[question.type]}</h1>
            <h1 style={{ maxWidth: "75%", textAlign: "center" }}>
              {questionText}
            </h1>
          </div>
        </div>
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton color="tertiary" id="open-modal">
            <IonIcon icon={chevronUp}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonModal
          trigger="open-modal"
          initialBreakpoint={0.33}
          breakpoints={[0, 0.33, 0.6]}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              maxHeight: "60%",
            }}
          >
            <IonCard style={{ flex: 1 }}>
              <IonCardHeader>
                <IonCardSubtitle>
                  {currQuestionIndex + 1} / {endQuestionIndex} Fragen
                </IonCardSubtitle>
                <IonCardTitle>
                  Noch {endQuestionIndex - currQuestionIndex - 1} Fragen
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent></IonCardContent>
              <IonButton
                fill="clear"
                disabled={!currQuestionIndex}
                onClick={() => onNextQuestion(false)}
              >
                Zurück
                <IonIcon slot="start" icon={arrowUndoCircleOutline}></IonIcon>
              </IonButton>
              <IonButton fill="clear" onClick={() => onNextQuestion()}>
                Weiter
                <IonIcon slot="end" icon={arrowRedoCircleOutline}></IonIcon>
              </IonButton>
            </IonCard>
            <IonCard style={{ flex: 1 }}>
              <IonCardHeader>
                <IonCardSubtitle>{0} Regeln aktiv</IonCardSubtitle>
                <IonCardTitle>Aktive Regeln</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>#TODO</IonCardContent>
            </IonCard>
            <IonCard style={{ flex: 1 }}>
              <IonCardHeader>
                <IonCardSubtitle>Deine Meinung zu dieser Frage</IonCardSubtitle>
                <IonCardTitle>Feedback</IonCardTitle>
              </IonCardHeader>
              <IonButton
                color="danger"
                fill="clear"
                onClick={() => rateQuestion(false)}
              >
                Schlecht
                <IonIcon slot="start" icon={thumbsDownOutline}></IonIcon>
              </IonButton>
              <IonButton
                color="success"
                fill="clear"
                onClick={() => rateQuestion(true)}
              >
                Gut
                <IonIcon slot="end" icon={thumbsUpOutline}></IonIcon>
              </IonButton>
            </IonCard>
          </div>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};
