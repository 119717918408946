import Papa from "papaparse";
//@ts-ignore
import questionsCSV from "./questions.csv";

export enum QuestionType {
  Task = 1,
  Rule = 3,
  Enumeration = 4,
  Choice = 14,
  Misc = 23,
  OnEx = 5,
}

export type Question = {
  key?: string;
  parent_key?: string;
  text: string;
  type: QuestionType;
  nb_players: number;
};

export type QuestionInstance = Question & {
  players: string[];
  sips: number[];
};

export const loadCSV = () =>
  new Promise<Papa.ParseResult<Question>>((res, rej) => {
    Papa.parse<Question>(questionsCSV, {
      download: true,
      header: true,
      transform: (val) => (!!val ? parseInt(val) || val : undefined),
      complete: (data) => {
        res(data);
      },
      error: rej,
    });
  });
