import {
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
} from "@ionic/react";
import "./StartPage.css";
import { PlayersCard } from "./PlayersCard";
import { StartGameCard } from "./StartGameCard";
import { SettingsCard } from "./SettingsCard";

export const StartPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader></IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonCol className="ion-align-items-center ion-justify-content-center">
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-around",
                margin: "24px 16px",
                alignItems: "center",
              }}
            >
              <IonAvatar style={{ flex: 2, height: "100%", flexBasis: "10%" }}>
                <img
                  alt="Jonnicolo Logo"
                  src="/assets/icons/icon-512x512.png"
                />
              </IonAvatar>
              <div style={{ flex: 1 }}></div>
              <IonCard style={{ flex: 8, margin: "24px 0" }}>
                <IonCardHeader>
                  <IonCardSubtitle>
                    Das beste Trinkspiel <br /> diesseits des Rio Pecos
                  </IonCardSubtitle>
                  <IonCardTitle style={{ fontFamily: "Action Man" }}>
                    Jonnicolo
                  </IonCardTitle>
                </IonCardHeader>
              </IonCard>
            </div>

            <PlayersCard />
            <StartGameCard />
            <SettingsCard />
          </IonCol>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
