import { useIonToast } from "@ionic/react";
import { FC, PropsWithChildren, useEffect } from "react";
import { useServiceWorker } from "../hooks/useServiceWorker";

export const UpdateWrapper: FC<PropsWithChildren> = (props) => {
  const [present] = useIonToast();
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  // decides when to show the toast
  useEffect(() => {
    if (showReload && waitingWorker) {
      present({
        message: "Eine neue Version ist verfügbar!",
        buttons: [{ text: "Aktualisieren", handler: reloadPage }],
      });
    }
  }, [waitingWorker, showReload, reloadPage, present]);

  return <>{props.children}</>;
};
