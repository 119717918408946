import { IonItem, IonInput, IonLabel } from "@ionic/react";
import { settings } from "ionicons/icons";
import { FunctionComponent } from "react";
import { formatPercentage, parsePercentage } from "../helpers";
import { QuestionType } from "../Questions";
import { distributionSet } from "../redux/settings";

type Props = {
  value: number;
  onChange: (value: number) => void;
  label?: string;
};
export const PercentageInput: FunctionComponent<Props> = (props) => {
  return (
    <IonItem>
      <IonInput
        className="alignInputsRight"
        label={props.label}
        type="number"
        value={formatPercentage(props.value)}
        min={"0"}
        max={"100"}
        onIonChange={(e) => props.onChange(parsePercentage(e.detail.value))}
      />
      <IonLabel slot="end">%</IonLabel>
    </IonItem>
  );
};
