import { QuestionType } from "./Questions";

export const MAX_DISTANCE_BETWEEN_FOLLOWUP = 7;
export const MIN_DISTANCE_BETWEEN_FOLLOWUP = 4;

export const QUESTION_TYPE_TITLE = {
  [QuestionType.Task]: null,
  [QuestionType.Rule]: "Regel",
  [QuestionType.Enumeration]: "Aufzählung",
  [QuestionType.Choice]: "Abstimmung",
  [QuestionType.Misc]: "Spiel",
  [QuestionType.OnEx]: "Auf Ex",
};

export const HEADING_STYLE: React.CSSProperties = {
  maxWidth: "75%",
  textAlign: "center",
  fontSize: "4em",
  fontFamily: "Action Man Shaded",
};

export const NAME_PLACEHOLDERS = [
  "Luke",
  "Leia",
  "Han",
  "Anakin",
  "Padmé",
  "Obi-Wan",
  "Qui-Gon",
  "Mace",
  "Jyn",
  "Cassian",
  "Lando",
  "Boba",
  "Jango",
  "Ahsoka",
  "James",
  "Pavel",
  "Jean-Luc",
  "William",
  "Geordi",
  "Beverly",
  "Chakotay",
  "B'Elanna",
  "Ezri",
  "Kira",
  "Miles",
  "Wesley",
];
