import { useIonRouter, useIonToast } from "@ionic/react";
import { useDispatch } from "react-redux";
import { generateQuestionList } from "./generateQuestionList";
import { settingsSet } from "./redux/settings";
import { store } from "./redux/store";
import type Seq from "seq-logging";

import { Question } from "./Questions";

global.Buffer = window.Buffer || require("buffer/").Buffer;
global.process = global.process || require("process");

const logger: Seq.Logger = new (require("seq-logging").Logger)({
  serverUrl: "https://seq.minenis.de:5342",
  onError: console.error,
  apiKey: "YUIJ1RRFYKB1sBZkQYSj",
});

export const useRateQuestion = (question: Question) => {
  const [present] = useIonToast();
  return (positive: boolean) => {
    logger.emit({
      timestamp: new Date(),
      level: "Information",
      messageTemplate:
        'The question "{question}" ({type}) was rated {rating} by {players}',
      properties: {
        question: question.text,
        type: question.type,
        rating: positive ? "positive" : "negative",
        players: store.getState().settings.players,
      },
    });
    present({
      message: "Danke für dein Feedback",
      duration: 1000,
      color: "success",
    });
  };
};

export const randomNumberBetween = (max: number, min: number = 0) => {
  return Math.round(Math.random() * (max - min) + min);
};

export const parsePercentage = (p: string | number | undefined | null) =>
  Math.max(
    Math.min(Math.round(parseFloat(p?.toString() ?? "0") * 100) / 10000, 1),
    0
  );

export const formatPercentage = (p: number) => (p * 100).toFixed(2);

export const shuffleList = <T>(items: T[]) => {
  return [...items].sort(() => 0.5 - Math.random());
};

export const selectN = <T>(items: T[], count: number = 2) => {
  const shuffled = [...items].sort(() => 0.5 - Math.random());

  // If count is greater than or equal to the length of the array, return a copy of the array
  if (count >= items.length) return shuffled;

  // Otherwise, select count unique items
  const selectedSet = new Set<T>();
  while (selectedSet.size < count) {
    const randomItem = items[Math.floor(Math.random() * items.length)];
    selectedSet.add(randomItem);
  }
  return [...selectedSet];
};

// Round to the nearest even number,
// But only for non-whole numbers
export const roundToBeEven = (num: number) => {
  return Math.floor(num) % 2 === 0 ? num : Math.ceil(num);
};

export const randomElement = <T>(items: T[]) =>
  items[Math.floor(Math.random() * items.length)];

export const useStartGame = () => {
  const router = useIonRouter();
  const dispatch = useDispatch();
  return async () => {
    const list = await generateQuestionList();
    dispatch(settingsSet({ key: "questions", value: list }));
    dispatch(settingsSet({ key: "questionIndex", value: 0 }));
    if (router.routeInfo.pathname.includes("game")) router.goBack();
    router.push("/page/game");
  };
};
