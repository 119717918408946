import { IonCard, IonCardContent, IonButton } from "@ionic/react";
import React, { FC } from "react";
import { useStartGame } from "../../helpers";

type Props = {};

export const StartGameCard: FC<Props> = (props) => {
  const startGame = useStartGame();

  return (
    <IonCard>
      <IonCardContent>
        <IonButton
          fill="outline"
          expand="block"
          color="tertiary"
          onClick={startGame}
        >
          Spiel starten
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};
