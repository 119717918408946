import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import { StartPage } from "./pages/StartPage/StartPage";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { GamePage } from "./pages/GamePage/GamePage";
import { UpdateWrapper } from "./components/UpdateWrapper";

const App: React.FC = () => {
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <IonApp>
          <IonReactRouter>
            <UpdateWrapper>
              <IonRouterOutlet id="main">
                <Route path="/" exact={true}>
                  <Redirect to="/page/Start" />
                </Route>
                <Route path="/page/Start" exact={true}>
                  <StartPage />
                </Route>
                <Route path="/page/game" exact={true}>
                  <GamePage />
                </Route>
              </IonRouterOutlet>
            </UpdateWrapper>
          </IonReactRouter>
        </IonApp>
      </Provider>
    </PersistGate>
  );
};

export default App;
